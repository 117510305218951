<template>
    <div class="app-global-wrapper" :class="{'dark-mode': isDarkMode}">
        <AppNavPanel v-if="isLoggedIn"/>
        <div class="app-content">
            <BreadcrumbsSection v-if="isLoggedIn"/>
            <router-view/>
        </div>
    </div>
    <div class="loader-wrapper" :class="{'hidden': !authStatuses.loginInProcess, 'dark-mode': isDarkMode}" v-if="$route.name != 'auth'">
        <img v-if="isDarkMode" src="./assets/images/logo-alt.svg" class="logo">
        <img v-else src="./assets/images/logo.svg" class="logo">
        <div class="loader line-scale-pulse-out">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
import AppNavPanel from './components/AppNavPanel.vue';
import BreadcrumbsSection from './components/BreadcrumbsSection.vue';

export default {
    data() {
        return {

        }
    },
    components: {
        AppNavPanel,
        BreadcrumbsSection
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters['isLoggedIn']
        },
        authStatuses() {
            return this.$store.getters['getAuthStatuses'];
        },
        isDarkMode() {
            return this.$store.getters['isDarkMode'];
        }
    },
    created() {
        if (localStorage.getItem('ionds-user')) {
            let credential = JSON.parse(localStorage.getItem('ionds-user'));
            if (credential.password) {
                this.$store.dispatch('loginWithEmailAndPassword', credential);
            } else {
                this.$store.dispatch('signInWithGoogleToken', credential);
            }
        } else{
            this.$router.replace('/auth');
        }
        if (localStorage.getItem('theme')) 
            this.$store.dispatch('toggleTheme', (localStorage.getItem('theme') == 'dark'));
    }
}
</script>

<style lang="less">
    @import './assets/less/index.less';
</style>
